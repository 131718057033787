import React from "react";
import Navbar from './components/navbar'
import About from './components/about'
// import Experience from './components/experience'
import Skills from './components/skills'
import Activities from './components/awards'
import Projects from "./components/projects";

export function App() {

  return (
    <>
      <Navbar />
      <div className="container-fluid p-0">
        <About />
        {/* <Experience /> */}
        <Skills />
        <Activities />
        <Projects />
      </div>
    </>
  );
}

export default App;
