import React from "react";
import { contentMyActivities } from "./../constants";


function Activities() {
  return (
    <section className="resume-section" id="activities">
      <div className="resume-section-content">
        <h2 className="mb-5">Activities</h2>

        <div className="timeline">
          {contentMyActivities[0].map((e, i) => {
            return (
              <div className="entry" key={i}>
                <div className="title">
                  <h3>{e.date}</h3>
                  <p>{e.place}</p>
                </div>
                <div className="body">
                  <p>{e.title}</p>
                  <ul>
                    {e.desc.map((l, j) => <li key={j}>{l}</li>)}
                  </ul>
                </div>
              </div>
            )
          })
          }
        </div>

        <h2 className="mb-3">Blogs</h2>
        <div className="row">
          {contentMyActivities[1].map((e, i) => {
            return (
              <div key={i} className="d-flex col-12 col-md-6 mt-5 mb-5 align-items-center ">
                <img className="imgAward mr-3" src={e.imgLink} alt="" />
                <a href={e.desc}>
                  {e.title}
                </a>
              </div>
            );
          })}
        </div>

        <h2 className="mb-3 mt-5">Accomplishments</h2>
        <div className="row">
          {contentMyActivities[2].map((e, i) => {
            return (
              <div key={i} className="d-flex col-12 col-md-6 mt-5 mb-5 align-items-center ">
                <img className="imgAward mr-3" src={e.imgLink} alt="" />
                <a s href={e.desc}>
                  {e.title}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Activities;
