import React, { PureComponent } from "react";

export class Navbar extends PureComponent {
    render() {
        return (
            <nav style={{ borderRight: "solid grey" }} className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
                <a className="navbar-brand js-scroll-trigger" href="#page-top">
                    <span className="d-block d-lg-none">Soheyb Merah</span>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#about">
                                About
                            </a>
                        </li>
                        {/* <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#experience">
                                Experience
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#skills">
                                Skills
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#activities">
                                Activities
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger" href="#projects">
                                Projects
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Navbar;
