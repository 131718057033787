import React from "react";
import { contentAboutMe } from "./../constants";

function Skills() {
    return (
        <section className="resume-section" id="skills">
            <div className="resume-section-content">
                <h2 className="mb-5">Skills</h2>
                <h3>Programming Languages</h3>
                <ul className="list-inline dev-icons">
                    {contentAboutMe[0].map((e, i) => {
                        return (
                            <li key={i} className="list-inline-item">
                                <img src={e.imgLink} width={86} height={86} alt="" />
                            </li>
                        );
                    })}
                </ul>
                <h3>Tools & Framworks</h3>
                <ul className="list-inline dev-icons">
                    {contentAboutMe[1].map((e, i) => {
                        return (
                            <li key={i} className="list-inline-item">
                                <a href={e.link} target="_blank" rel="noopener noreferrer" ><img src={e.imgLink} width={100} height={100} alt="" /></a>
                            </li>
                        );
                    })}
                </ul>
                {/* <div className="subheading mb-3">Knowledge</div>
                <ul className="list-inline dev-icons">
                    {contentAboutMe[3].map((e, i) => <li key={i} className="list-p">
                             <p><strong>{e.title}:</strong> {e.desc}.</p>
                            </li>
                    )}
                </ul> */}
            </div>
        </section>
    );
}

export default Skills;
