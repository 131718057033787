import React from "react";
import { contentProjectsAmProudOf } from "./../constants";


export default function Projects() {
    return (
        <section className="resume-section" id="projects">
            <div className="resume-section-content">
                <h2 className="mb-5">Projects</h2>

                <div className="col">
                    {contentProjectsAmProudOf.map((e, i) => {
                        return (
                            <div key={i} className="col mt-5 mb-5 align-items-center ">
                                <h3>{e.title}</h3>
                                <p>{e.desc}</p>
                                <a href={e.link}>Visit Project</a>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
